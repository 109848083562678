.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    select {
        background-color: transparent !important;
        color: #fff;
        padding: .75rem 1rem .5rem .5rem;
        border-radius: 12px;
        border: 1px solid #5625d8;
    }
    select option {
        background-color: #212121;
        border: 1px solid transparent
        
    }

}