.container {
    background-color: #292929;
    padding: .5rem;
    width: 100%;
    .subContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fname {
            margin: 0;
            color: #fff;
        }
        .right {
            display: flex;
            align-items: center;
        }
        .date {
            margin: 0;
            color: #fff;
        }
        .pipe {
            color: rgb(112, 123, 221);
            font-weight: bold;
            padding: 0 .5rem;
        }
        .imageContainer {
            display: flex;
            align-items: center;
            gap: .5rem;
            .img {
                height: 20px;
            }
        }
    }
}

.modalContainer {
    width: 700px;
    background-color: #1c1c1c;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    padding: 2rem;
    .header {
        margin: 0;
        color: #fff;
    }
    p {
        color: lightgray;
    }
    .btnContainer {
        display: flex;
        justify-content: center;
        gap: 1rem;
        .cancelButton {
            background-color: transparent;
            border: 1px solid #8160D7;
            padding: .5rem 1rem;
        }
        .archiveButton {
            background-color: transparent;
            border: 1px solid #ff6666;
            color: #ff6666;
            padding: .5rem 1rem;
        }
    }
    .fieldsContainer {
        width: 100%;
        border-top: 1px solid lightgrey;
        text-align: left;
        padding-top: 1rem;
        .itemTitle {
            color: #fff;
        }
        .field {
            background-color: #3b3b3b;
            padding: .5rem;
            color: lightgrey;
        }
        .fieldSubContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }
    .btnGroup {
        display: flex;
        justify-content: center;
        gap: 1rem;
        .button {
            border: 2px solid #635CEF;
            background-color: transparent;
            color: #fff;
            border-radius: 8px;
            padding: .5rem 1rem;
        }
    }
}