.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        text-align: center;
    }
    .divider {
        background-color: #5625d8;
        height: 2px;
        width: 300px;
        margin-bottom: 10px;
    }
    p {
        text-align: center;
        max-width: 700px;
        font-style: italic;
        font-size: .8rem;
        color: #fff;
    }
    form {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 800px;
        gap: 1rem;
        margin-bottom: 3rem;
        label {
            font-size: 1rem;
            margin-left: .5rem;
        }
        input {
            background-color: transparent;
            border: 1px solid #5625d8;
            font-size: 1rem;
            color: #fff;
            padding: .5rem;
            border-radius: 12px;
        }
        textarea {
            background-color: transparent;
            border: 1px solid #5625d8;
            border-radius: 12px;
            padding: .5rem;
        }
        button {
            background-color: #5625d8;
            width: 200px;
            border-radius: 12px;
            padding: 1rem;
            border: none;
            color: #fff;
            font-size: large;
            margin: 2rem auto;
        }
    }
}