.container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    .divider {
        height: 1px;
        width: 100%;
        flex: 3;
        background-color: lightgrey;
    }
    h5 {
        margin: 0;
        padding: 0 1rem;
    }
}